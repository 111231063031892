import React, { Component } from 'react';
import Layout from '../../components/layout';
// import quote from "../../img/quote.png"
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Page from '../../components/page';
import BannerComponent from '../../components/page/bannerComponent';

const Article = (data) => {
  let articleData = data.pageContext.product;
  if (!articleData) return false;
  let pledgeBanner = articleData.data.pledgeBanner;

  return (
    <Layout>
      <SEO
        title={articleData.SEOtitle}
        description={articleData.SEOdescription}
        image={articleData.SEObanner}
      />
      <header
        className='text-center article-header'
        style={{
          backgroundImage: `url(${global.fileserver + pledgeBanner.banner})`,
        }}>
        <Link
          to={pledgeBanner.url}
          className='btn btn-light bg-white text-primary my-4 px-5 btn-round'>
          {pledgeBanner.buttonText}
        </Link>
      </header>

      <div className='my-5'>
        <section className='container'>
          <div className='text-center'>
            <h1 className='article-title'>{articleData.title}</h1>
            <small className='text-light'>{articleData.date}</small>
          </div>

          <div className='article-banner my-5'>
            <BannerComponent
              value={articleData.banner}
              type={1}></BannerComponent>
          </div>

          {/* <div
          dangerouslySetInnerHTML={{
            __html: articleData.content,
          }}></div> */}
        </section>

        <Page content={articleData.content}></Page>
      </div>
    </Layout>
  );
};

// export const pageQuery = graphql`
//   query article($mysqlId: Int) {
//     mysqlArticles(mysqlId: { eq: $mysqlId }) {
//       SEObanner
//       SEOdescription
//       SEOtitle
//       banner
//       createdAt
//       date(formatString: "Do MMMM YYYY")
//       description
//       content
//       data
//       id
//       priority
//       status
//       title
//       updatedAt
//       mysqlId
//       categoryId
//     }

//     allFile {
//       nodes {
//         name
//         ext
//         childImageSharp {
//           fluid(maxWidth: 1920) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
// `;

export default Article;
